.editor {
  box-sizing: border-box;
  cursor: text;
  border-radius: 0.5em;
  position: relative;
}

.editor:not(.read-only) {
  background: #ffffff;
  border: 1px solid #ddd;
}

.editor:not(.read-only) .public-DraftEditor-content{ 
  padding: 1em;
  min-height: 8em;
}

.editor:not(.read-only) .public-DraftEditorPlaceholder-root {
  padding: 1em 1em 0 1em;
  position: absolute;
  pointer-events: none;
  color: hsla(0, 0%, 40%, 1);
}


.editor .public-DraftEditor-content .draftJsEmojiPlugin__image__192TI {
  width: 100%;
}

.editor .public-DraftEditor-content .draftJsMentionPlugin__iframeContainer__21EVZ  {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.editor .public-DraftEditor-content .draftJsMentionPlugin__iframe__stjRT {
  position: absolute;
  width: 100%;
  height: 100%;
}

